import React from "react"
const Bnr =
  "https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/cta_bnr.png"

export const CvBtn = () => {
  return (
    <section className="md:py-20 py-10 px-4 md:px-0">
      <div className="mx-auto" style={{ maxWidth: "1000px" }}>
        <a
          className="block hover:opacity-70"
          href="https://hub.park-lot.com/free-consulting"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={Bnr}
            alt="施策内容や見積もりについて、お気軽にご相談ください。無料相談の予約はこちら"
          />
        </a>
      </div>
      {/* <p className="description font-semibold text-white">
        施策内容や見積もりについて、お気軽にご相談ください
      </p>
      <div className="button">
        <a
          className="link leading-normal text-white font-semibold rounded-lg block"
          href="https://hub.park-lot.com/free-consulting"
          target="_blank"
          rel="noreferrer"
        >
          無料相談を予約
        </a>
      </div> */}
    </section>
  )
}
