import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const CookieRegulation = ({ location }) => {
  const title =
    "Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="Twitterのハッシュタグキャンペーンとは？効果の出るハッシュタグの見つけ方を解説します"
          pagedesc="効果の出るTwitterハッシュタグキャンペーンの実施方法や、認知度が向上するハッシュタグの見つけ方を解説します。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/twitter-hashtag-campaign/twitter-hashtag-campaign-1.jpg"
          pagepath="/blog/twitter-hashtag-campaign-1"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は4分で読むことができます
            </div>
            <h1>
              Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">2022年9月22日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="#HASHTAGと書かれたマグカップ"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/twitter-hashtag-campaign/twitter-hashtag-campaign-1.jpg"
                className="w-full"
              />
            </figure>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    ハッシュタグとは
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    ハッシュタグキャンペーンとは？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    ハッシュタグキャンペーンの仕組みは？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    ハッシュタグキャンペーンのメリット
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        1. ブランド認知度が上がる
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        2. アカウントが発見されやすくなる
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        3. 商品やイベントのプロモーションになる
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id8" className="toc__link">
                        4. 情報収集ができる
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        5. エンゲージメントが向上する
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id10" className="toc__link">
                    良いハッシュタグの付け方は？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id11" className="toc__link">
                    ハッシュタグキャンペーンの始め方
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id12" className="toc__link">
                    ハッシュタグ付きツイートを収集するには？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id13" className="toc__link">
                    まとめ
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <p>こんにちは、PARKLoT マーケティング担当の豊田桃子です！</p>
              <p>
                SNSを使ったマーケティング施策の一つに「ハッシュタグキャンペーン」があげられます。
              </p>
              <p>
                「#とハッシュタグ」をつけてユーザに投稿するだけでキャンペーンに参加できる手軽さと、フォロワーを通した情報拡散ができるのが大きな特徴です。
              </p>
              <p>
                ハッシュタグは一般的には「#本好きな人とつながりたい」など趣味が合う人をSNSで見つけたり、「#北海道」など、日記のように記録をする目的で活用されることが多いですが、企業のマーケティング活動にも頻繁に利用されます。
                <br />
                <br />
                また、効果的なハッシュタグを使用することで、SNS施策の効果をより感じやすくしてくれるものでもあります。
              </p>
              <p>
                今回の記事では、ハッシュタグキャンペーンの概要とメリット、運用方法についてお知らせします。
                <br />
                <br />
              </p>
              <p>この記事は次のような人にオススメです！</p>
              <ul className="blog__border-list">
                <li>
                  Twitter ハッシュタグキャンペーンを開催しようと考えている方
                </li>
                <li>
                  Twitter ハッシュタグキャンペーンの効果について知りたい方
                </li>
              </ul>
            </div>
            <div>
              <h2 id="id1">ハッシュタグとは</h2>
              <p>
                ハッシュタグが「#」の後に続く文字や言葉であることは、すでにご存知でしょう。
                <br />
                ハッシュタグは、SNSの投稿をカテゴリに分ける目的があります。
              </p>
              <p>
                ハッシュタグをつけて投稿がカテゴリ分けされることで、ユーザが特定のトピックを検索、フォローしやすくなるのです。
              </p>
              <p>
                驚くべきことに、ハッシュタグはSNS上で誕生したのではありません。
              </p>
              <p>
                <a
                  href="https://socialmediaweek.org/blog/2018/02/history-hashtags-symbol-changed-way-search-share/#:~:text=In%201988%2C%20the%20first%20hash,information%20they%20were%20looking%20for."
                  target="_blank"
                  rel="noreferrer"
                >
                  History of Hashtags: How a Symbol Changed the Way We Search
                  &amp; Share
                </a>
                によると、Twitterが誕生する前、Internet Relay
                Chat(IRC)という組織が、ネット上の情報をラベル付け、グループ分けするためにハッシュタグを使用していました。
                <br />
                Twitter社がこのアイデアを採用したのです。
              </p>
              <p>
                <br />
                下記のツイートが、Twitterで初めてハッシュタグ付きで投稿されたツイートです。
              </p>
              <blockquote className="twitter-tweet">
                <p lang="en" dir="ltr">
                  how do you feel about using # (pound) for groups. As in{" "}
                  <a href="https://twitter.com/hashtag/barcamp?src=hash&amp;ref_src=twsrc%5Etfw">
                    #barcamp
                  </a>{" "}
                  [msg]?
                </p>
                &mdash; Chris Messina (@chrismessina){" "}
                <a href="https://twitter.com/chrismessina/status/223115412?ref_src=twsrc%5Etfw">
                  August 23, 2007
                </a>
              </blockquote>
              <p>
                <br />
                ハッシュタグは、個人用から企業のブランディングまで幅広く活用され、全てのTwitterユーザのタイムラインに毎日表示されています。
              </p>
            </div>
            <div>
              <h2 id="id2">ハッシュタグキャンペーンとは？</h2>
              <p>
                ハッシュタグキャンペーンとは、SNSを活用したキャンペーン施策です。
              </p>
              <p>
                指定の「#（ハッシュタグ）」をつけて文字や画像と一緒にSNSに投稿してもらうことで、ユーザはキャンペーンに参加することができます。
              </p>
              <p>
                広告などに代表される企業からの情報発信は、ユーザーに敬遠される傾向にあります。しかし、ハッシュタグキャンペーンでは自社商品に関心の高いフォロワーを通してまだ関心の薄い層へと、口コミのように段階的に情報を届けていくことができます。
              </p>
              <p>
                一見簡単にキャンペーンを企画できるように感じますが、オンライン上のユーザにキャンペーンに魅力を感じてもらい、行動してもらうためにハッシュタグを効果的に使用する必要があります。
                また、コンテンツのクオリティ、メッセージ、キャンペーンのタイミングはとても重要です。
              </p>
            </div>
            <div>
              <h2 id="id3">ハッシュタグキャンペーンの仕組みは？</h2>
              <p>
                ハッシュタグキャンペーンはとてもシンプルなキャンペーンです。効果的なハッシュタグを考え、魅力的なツイートと共に投稿するだけです。
              </p>
              <p>
                ユーザは、ツイートを見て、ハッシュタグをつけて投稿またはリプライします。これでキャンペーンに参加完了です。
              </p>
              <p>
                あとはキャンペーンが終わるのを待つだけ。キャンペーン終了後に指定のハッシュタグをつけて投稿されているツイートを収集し、当選者を決定します。
              </p>
              <p>
                <br />
                上記のプロセスをまとめると、下記の手順になります。
              </p>
              <ol className="blog__border-list">
                <li>キャンペーンツイートを投稿する</li>
                <li>
                  ユーザはツイートを見て、指定のハッシュタグをつけて投稿・引用リツイート・リプライのいずれかを行う
                </li>
                <li>キャンペーン終了後にデータを収集し、当選者を決定する</li>
                <li>当選者に連絡を行う</li>
              </ol>
            </div>
            <div>
              <h2 id="id4">ハッシュタグキャンペーンのメリット</h2>
              <p>
                ハッシュタグキャンペーンは、Twitterで人気の「フォロー＆リツイートキャンペーン」よりも参加者の手間が増えてしまいます。
                <br />
                しかし、ハッシュタグキャンペーンを実施するメリットもたくさんあります。
              </p>
            </div>
            <div>
              <h3 id="id5">1. ブランド認知度が上がる</h3>
              <p>
                認知度の高いブランドはブランド力のあるハッシュタグを使っています。
              </p>
              <p>
                例えば、コカ・コーラ社は
                <a
                  href="https://twitter.com/hashtag/%E3%81%8A%E3%81%93%E3%81%9F%E3%81%93%E3%81%AB%E3%81%AF%E7%B5%B6%E5%AF%BE%E3%82%B3%E3%83%BC%E3%82%AF?src=hashtag_click"
                  target="_blank"
                  rel="noreferrer"
                >
                  「#おこたこには絶対コーク」
                </a>
                や
                <a
                  href="https://twitter.com/hashtag/%E3%82%B9%E3%83%BC%E3%83%91%E3%83%BC%E3%81%A7ENHYPEN%E3%82%92%E8%A6%8B%E3%81%A4%E3%81%91%E3%82%88%E3%81%86?src=hashtag_click"
                  target="_blank"
                  rel="noreferrer"
                >
                  「#スーパーでENHYPENを見つけよう」
                </a>
                といった独自のハッシュタグを生み出し、認知度を向上しています。
              </p>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ／
                  <a href="https://twitter.com/hashtag/%E3%81%8A%E3%81%93%E3%81%9F%E3%81%93%E3%81%AB%E3%81%AF%E7%B5%B6%E5%AF%BE%E3%82%B3%E3%83%BC%E3%82%AF?src=hash&amp;ref_src=twsrc%5Etfw">
                    #おこたこには絶対コーク
                  </a>{" "}
                  <br />
                  写真投稿キャンペーン締切迫る📣
                  <br />＼
                  <a href="https://twitter.com/hashtag/%E3%82%B3%E3%82%AB%E3%82%B3%E3%83%BC%E3%83%A9?src=hash&amp;ref_src=twsrc%5Etfw">
                    #コカコーラ
                  </a>{" "}
                  と一緒におこたこを食べて、毎週500名にCoke
                  ONドリンクチケットが当たる✨
                  <br />
                  <br />
                  🔽応募方法
                  <br />
                  1.
                  <a href="https://twitter.com/CocaColaJapan?ref_src=twsrc%5Etfw">
                    @CocaColaJapan
                  </a>{" "}
                  をフォロー
                  <br />
                  2.
                  <a href="https://twitter.com/hashtag/%E3%81%8A%E3%81%93%E3%81%9F%E3%81%93%E3%81%AB%E3%81%AF%E7%B5%B6%E5%AF%BE%E3%82%B3%E3%83%BC%E3%82%AF?src=hash&amp;ref_src=twsrc%5Etfw">
                    #おこたこには絶対コーク
                  </a>{" "}
                  を付けて、お好み焼orたこ焼とコークを一緒に楽しんでいる写真を投稿
                </p>
                &mdash; コカ･コーラ (@CocaColaJapan){" "}
                <a href="https://twitter.com/CocaColaJapan/status/1561639319311245312?ref_src=twsrc%5Etfw">
                  August 22, 2022
                </a>
              </blockquote>{" "}
              <script
                async
                src="https://platform.twitter.com/widgets.js"
                charSet="utf-8"
              ></script>
            </div>
            <div>
              <h3 id="id6">2. アカウントが発見されやすくなる</h3>
              <p>
                ハッシュタグは、SNSマーケティングの基本的なSEO対策の一つです。
                <br />
                <br />
                ハッシュタグを利用することで、企業アカウントやキャンペーン、その他の数多くの投稿をユーザが見つけることができるのです。ハッシュタグは、SNS上でアカウントを発見しやすくしてくれます。
              </p>
              <p>
                また、効果的なハッシュタグをつけることで公式サイトへの流入が増える可能性があり、SEO対策に効果があります。
              </p>
            </div>
            <div>
              <h3 id="id7">3. 商品やイベントのプロモーションになる</h3>
              <p>
                数多くの広告ツイートがユーザのフィードに表示される中、自社ブランドを宣伝することはとても難しく感じるかもしれません。
              </p>
              <p>
                しかし、ハッシュタグキャンペーンをプロモーションツールとして使うことで、自社商品やサービスを広告感を感じさせず紹介することができます。
              </p>
            </div>
            <div>
              <h3 id="id8">4. 情報収集ができる</h3>
              <p>
                UGC（User Generated
                Contentsは一般ユーザーによって生成された、TwitterやInstagramなどのSNSに投稿された写真・動画コンテンツを指します。）によって、有益な情報を集めることができます。
              </p>
              <p>
                ハッシュタグをシェアしてもらうことで、ユーザが何を考えどのようなトピックに興味があるかの情報収集ができるのです。集めたデータを次のキャンペーン施策に生かすことができます。
              </p>
              <p>
                このようにハッシュタグキャンペーンをモニタリングすることはブランドの成功を発見する手がかりになります。
              </p>
            </div>
            <div>
              <h3 id="id9">5. エンゲージメントが向上する</h3>
              <p>
                効果的なハッシュタグキャンペーンを実施することで、ユーザエンゲージメントの向上、フォロワーの増加が期待できます。
              </p>
              <p>
                ハッシュタグキャンペーンの目的はユーザに行動してもらうことです。簡単に参加してもらえるハッシュタグキャンペーンを実施しましょう。
              </p>
            </div>
            <div>
              <h2 id="id10">良いハッシュタグの付け方は？</h2>
              <p>
                <a
                  href="https://business.twitter.com/en/blog/secret-to-effective-hashtag-campaign.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  The secret to using hashtags effectively in ad
                  campaigns（Twitter社公式ブログ）
                </a>
                によると、良いハッシュタグをつけるには、「調査すること」が大事と書かれています。
              </p>
              <p>
                <br />
                下記を意識して、しっかりと調査を行いましょう。
              </p>
              <ul className="blog__border-list">
                <li>
                  どのようなハッシュタグが自社アカウントのブランドに関連しているか？
                </li>
                <li>競合はどのようなハッシュタグを使っているか？</li>
                <li>
                  ターゲットユーザは、どのようなツイートやハッシュタグに興味があるか？
                </li>
                <li>ハッシュタグは覚えやすく、かつタイピングしやすいか？</li>
              </ul>
            </div>
            <div>
              <h2 id="id11">ハッシュタグキャンペーンの始め方</h2>
              <p>
                「キャンペーン実施のために、やらないといけないことがあれも、これも….」という方も、ご安心ください！
              </p>
              <p>ハッシュタグキャンペーン実施の手順を8つにまとめました。</p>
              <ol className="blog__border-list">
                <li>キャンペーンの目的を決める</li>
                <li>マーケットリサーチを行う</li>
                <li>競合のキャンペーンをリサーチする</li>
                <li>ターゲットユーザを決める</li>
                <li>
                  効果的なハッシュタグを決める
                  <ul>
                    <li>自社アカウントを想起させるハッシュタグは何か</li>
                    <li>
                      競合のアカウントはどんなハッシュタグを使っているのか
                    </li>
                    <li>
                      対象としているユーザはどのようなハッシュタグやツイートに興味があるのか
                    </li>
                    <li>ハッシュタグは短く、覚えやすいか？</li>
                  </ul>
                </li>
                <li>キャッチーなツイート文とクリエイティブを作る</li>
                <li>キャンペーンを開始する</li>
                <li>キャンペーンをモニタリングする</li>
              </ol>
            </div>
            <div>
              <h2 id="id12">ハッシュタグ付きツイートを収集するには？</h2>
              <p>
                ハッシュタグ付きツイートを収集するのは、手作業で行うと莫大な時間がかかります。ツールの導入を検討しましょう。
              </p>
              <p>
                PARKLoTでは、指定のハッシュタグ付きツイートを指定の期間収集することができます。
              </p>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="PARKLoTのハッシュタグ収集画面"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/twitter-hashtag-campaign/twitter-hashtag-campaign-2.png"
              />
            </div>
            <div>
              <h2 id="id13">まとめ</h2>
              <p>
                今回は、ハッシュタグキャンペーン開催について詳しく説明しました。この記事で伝えたいことは、以下の3つのポイントです。
              </p>
              <ul className="blog__border-list">
                <li>ハッシュタグキャンペーンはブランド認知度が高まる</li>
                <li>
                  キャンペーンの実施には「効果的なハッシュタグ」を選ぶ必要がある
                </li>
                <li>
                  ハッシュタグ付きツイートの収集はツールの導入を検討する　
                </li>
              </ul>
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="momoko" />
            <div className="blog__more-articles">
              <h2 className="text-center">
                こちらの記事もあなたにおすすめです
              </h2>
              <ul>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/comparison-no-tool"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンで本当にツールは必要なのか？
                    お金をかけずに手動でできる方法は？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/maximize-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンの効果を最大化させる方法 6選
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/customer-appeal-SNS"
                    target="_blank"
                    rel="noreferrer"
                  >
                    訴求効果ピカイチ!!
                    手軽で効果的「SNS広告」2大スタイル徹底解説～フォロー＆リツイートキャンペーンとハッシュタグキャンペーンどちらをやるべき？
                    ～
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default CookieRegulation
